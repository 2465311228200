import React from 'react';

//const throttle = require('lodash.throttle');
import throttle from 'lodash/throttle';


// see https://github.com/baobabKoodaa/gatsby-starter-infinite-scroll/

export class InfiniteScroll extends React.Component {


  // scroll handler
  constructor(props) {
    super(props)
    this.scrollHandler = this.scrollHandler.bind(this)
    this.resizeHandler = this.resizeHandler.bind(this)
  }

  scrollHandler = () => {}

  resizeHandler = () => {}

  componentDidMount() {
    this.scrollHandler = throttle(this.checkWindowScroll, this.props.throttle);
    this.resizeHandler = throttle(this.checkWindowScroll, this.props.throttle);

    window.addEventListener('scroll', this.scrollHandler);
    window.addEventListener('resize', this.resizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('resize', this.resizeHandler);
  }

  componentDidUpdate() {
    this.scrollHandler();
  }

  checkWindowScroll = () => {

    if ( 
      ( this?.sentinel?.getBoundingClientRect().top - window.innerHeight > 0 ) && 
      ( this?.sentinel?.getBoundingClientRect().top - window.innerHeight > 0) ) {
        console.log("in window", this)
      }
    //console.log("Window height " + window.innerHeight + " Sentinel boundingRectTop " + this.sentinel.getBoundingClientRect().top)
    if (
      this.props.hasMore &&
      (this?.sentinel?.getBoundingClientRect().top | 0 )  - window.innerHeight < this.props.threshold
    ) {
      //console.log("load more triggered by", this.props)
      this.props.onLoadMore();
    }
  }

  render() {
    const sentinel = <div ref={i => this.sentinel = i} />;

    //console.log("displaying", this.props)
    if(this.props.render) {
        //console.log("adding sentinal to render")
        return this.props.render({
          sentinel,
          children: this.props.children
        });
      }
    if(this.props.component) {
        //console.log("adding sentinal to component")
        const Container = this.props.component;
        return (
            <Container sentinel={sentinel}>
            {this.props.children}
            </Container>
        );
    }
    //console.log("adding sentinal to div")
    return (
      <div>
        {this.props.children}
        {sentinel}
      </div>
    );
  }
}