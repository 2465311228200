import React from "react"
import { Link } from "gatsby"
import { InfiniteScroll } from "./infiniteScroll.js"
import { FaCog } from "react-icons/fa"
import InfiniteScrollGrid from "./infiniteScrollGrid.js"
import BlogPost from './blog-post'
import BlogPostInfinite from "./blog-post-infinite.js"

/** View for "home" page with infinite scroll and fallback to pagination. */
// see https://github.com/baobabKoodaa/gatsby-starter-infinite-scroll/
class View extends React.Component {
    constructor(props) {
        super(props)
        console.log(props.article)
        // key="blogpost" article={article} search_index={process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME} pageUrl={pageUrl} siteSettings={siteSettings} categoryPages={categoryPages}
        if (props.globalState.isInitializing() || !props.globalState.useInfiniteScroll) {
            const pageKey = props.article.slug
            //console.log(`View is initializing items according to ${pageKey}.`)
            props.globalState.updateState({
                startingPage: props.article.slug,
                [pageKey]: props.pageContext?.pageImages,
                //cursor: props.globalState.cursor + 1,
            })
        }
    }

    render() {
        const article = this.props.article
        const pageUrl = this.props.pageUrl
        const siteSettings = this.props.siteSettings
        const categoryPages = this.props.categoryPages
        const g = this.props.globalState
        //console.log("globalState", g)
        const pageContext = this.props.pageContext
        const paginationData = {
            currentPage: article.slug,
            countPages: 1, //pageContext.countPages,
            useInfiniteScroll: g.useInfiniteScroll,
        }

        // if this page isn't in the journey then add it

        
        if (!g.journey.includes("/"+article.slug+"/")){
            g.journey.push("/"+article.slug+"/")
        } 


        return (
            <>

                { /* Load current page and add this to the journey */ }
                <BlogPost globalState={g} key="post" article={article} search_index={process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME} pageUrl={pageUrl} siteSettings={siteSettings} categoryPages={categoryPages}/>
                

                <InfiniteScrollGrid globalState={g} pageContext={pageContext} key="blogpost" article={article} search_index={process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME} pageUrl={pageUrl} siteSettings={siteSettings} categoryPages={categoryPages} />
                
                {/* Infinite Scroll */}
                <InfiniteScroll throttle={50} threshold={1000} hasMore={g.hasMore(pageContext) && g.articles.length < 25 && !g.stopUpdating} onLoadMore={g.loadMore}>
                    {/* Grid given as a child element for Infinite Scroll. */}
                    {g?.articles && g.articles.map((i, index) => (
                        <span key={`${i.id}_${index}`}>
                            <BlogPostInfinite article={i} search_index={process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME} page_number={index}/>
                            {//next_page(index, [128,129])
                            }
                        </span>
                    ))}
                </InfiniteScroll>

                {/* Nothing left to see. */}
                {g.useInfiniteScroll && g.cursor !== 0 && !g.hasMore(pageContext) && ( <></> )}

                {/* Loading spinner. */}
                {(g.cursor === 0 || g.hasMore(pageContext)) && (<></>)}

            </>
        )
    }
}

export default View