import React from "react";

import {getPageUrl} from '../lib/helpers'

import AdSense from "./adsense";
import ArticleHeader from "../components/article-header"
import ArticleWrapper from "../components/article-wrapper"
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import DynamicZoneComponent from '../components/dynamic-zone-component'
import OfferAffiliateNotice from "../components/offer-affiliatenotice"
import RelatedPages from '../components/related-pages'
import SearchBar from '../components/search-bar'

//import HeroAdSpace from "../components/hero-ad-space"
//import HeroMiniAdSpace from "../components/hero-mini-ad-space"

import * as styles from './blog-post.module.css'


function string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaaeeeeiiiioooouuuunc------";

    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}


function BlogPost({globalState, article, metadata, search_index, pageUrl, siteSettings, categoryPages}) {

  var is_affiliate = []
  // loop through data and determine if there are affiliate offers
  // check list for offers
  if(article.body){
      for(let i = 0; i < article.body.length; i++){
          if (article.body[i].offers){
              for(let j = 0; j < article.body[i].offers.length; j++){
                is_affiliate.push(true)
              }
          }
      }
  }

  // devine section numbers if more than one
  var sectionNumber = 0
  var nth_ad = 0
  // 102 under_first_paragraph
  // 106 bottom_of_page
  var ad_ids = []
  
  if (process.env.EZOIC_ACCOUNT_ID) {
      ad_ids = [
            118, // mid_content
            119, // long_content
            120, // longer_content
            121, // longest_content
            122, // incontent_5
            110, // incontent_6
            123, // incontent_7
            124, // incontent_8
            125, // incontent_9
            126, // incontent_10
            127  // incontent_11
        ]
    }
  if (process.env.ADSENSE_CLIENT_ID) {
      ad_ids = [8499360713,8499360713,8499360713,8499360713]
  }

  var index_actual = 0
  return (
    <>

    <article>
        <ArticleHeader article={article} pageUrl={pageUrl} categoryPages={categoryPages}/>
            <ArticleWrapper article={article}>
                { article.body_introduction && article.body_introduction.data && 
                
                    <section id="introdction" dangerouslySetInnerHTML={{__html:article.body_introduction.data.childMarkdownRemark.html}} />
                
                }



                    {
                        // display body components table of contents
                        article?.body && article.body.length > 2 &&
                            <nav id="toc">
                                <span className={styles.toc}>Contents:</span>
                                <div>
                                    <ul className={styles.toc_list}>
                                    { article.body.map((component,index) => {
                                    
                                        var indent_start = ""
                                        var indent_end = ""
                                        if(index_actual<4){ 
                                            if(component.strapi_component == "segments.list-top-n"){
                                                index_actual = index_actual +1
                                            }
                                                
                                            if(component.header_level==3 ){
                                                
                                                return component && (component.title || component.question) && 
                                                    (
                                                        // if there is a title, then add it to table of contents
                                                        <li key={"shortcut_"+index}>
                                                            { component.title &&
                                                            <a href={getPageUrl(article)+"#"+string_to_slug(component.title)}>{component.title}</a>
                                                            }
                                                            { component.question &&
                                                            <a href={getPageUrl(article)+"#"+string_to_slug(component.question)}>{component.question}</a>
                                                            }
                                                        </li>
                                                    )
                                            } else {
                                                return component && (component.title || component.question) && 
                                                    (
                                                        // if there is a title, then add it to table of contents
                                                        <li key={"shortcut_"+index}>
                                                            { component.title &&
                                                            <a href={getPageUrl(article)+"#"+string_to_slug(component.title)}>{component.title}</a>
                                                            }
                                                            { component.question &&
                                                            <a href={getPageUrl(article)+"#"+string_to_slug(component.question)}>{component.question}</a>
                                                            }
                                                        </li>
                                                    )
                                            }
                                        }
                                        }
                                    )
                                
                                    }
                                    {
                                        index_actual == 4 && 
                                        <li key={"shortcut_more"}>
                                            <>Scroll for More...</>
                                        </li>
                                    }
                                    </ul>
                                </div>
                            </nav>
                    }
                    
                    { process.env.EZOIC_ACCOUNT_ID && article.body_introduction && 
                        <Container>
                            <aside className={styles.centeredAd} dangerouslySetInnerHTML={{ __html: `<div id="ezoic-pub-ad-placeholder-102"> </div>`}}/>
                        </Container>
                    }

                    {
                    // add children posts if any
                    article.childPages && article.childPages.length > 0 && (
                        <BlogPostPreviewList
                            nodes={article.childPages}
                            max_n="100"
                            page_type={article.page_type}
                            ad_ids={ad_ids}
                            page_has_parent={article}
                        />
                    )}

                    {
                        // show affiliate notice
                        is_affiliate && is_affiliate.length > 0 &&
                        <aside style={{"textSize":"1rem"}}>
                            <OfferAffiliateNotice></OfferAffiliateNotice>
                        </aside>
                    }

                    {
                        // display body components
                        article.body && article.body.map((component, index) => {
                            // increment
                            var anchor = null
                            var indexText = ""
                            var show_ad = false
                            var show_ad_mobile = false
                            if ((index) % 2 === 0){
                                // show desktop on every other
                                nth_ad = nth_ad + 1
                                show_ad = true
                            } else if ((index) % 1 === 0 && component.__typename != 'STRAPI__COMPONENT_RELATED_CALLOUT_PAGES'){
                                // else show mobile ad
                                nth_ad = nth_ad + 1
                                show_ad_mobile = true
                            }
                            if (component.title){
                                anchor = string_to_slug( component.title )
                                sectionNumber = sectionNumber + 1
                                indexText = sectionNumber+". "
                            }
                            if (component.question){
                                anchor = string_to_slug( component.question )
                                sectionNumber = sectionNumber + 1
                                indexText = sectionNumber+". "
                            }
                            
                            // loop back to the beginning of list
                            nth_ad = nth_ad % ad_ids.length

                            return (component && 
                                <span key={"comp_"+index}>
                                    { process.env.EZOIC_ACCOUNT_ID && show_ad
                                        ? <aside>
                                            <div className={styles.sectionFullwidth} key={`ad_`+nth_ad}>
                                                <div className={styles.centeredAd} dangerouslySetInnerHTML={{ __html: `
                                                    <div id="ezoic-pub-ad-placeholder-`+ad_ids[nth_ad]+`"> </div>`}}/>
                                            </div>
                                        </aside>
                                        : null
                                    }
                                    {process.env.ADSENSE_CLIENT_ID && show_ad &&
                                            <aside style={{"textSize":"1rem"}}>
                                                <div className={styles.sectionFullwidth} key={`ad_`+nth_ad}>
                                                    <AdSense id={ad_ids[nth_ad]} style="inline1" layout="-fc+5g+70-cl-1m"/>
                                                </div>
                                            </aside>
                                    }
                                    {process.env.ADSENSE_CLIENT_ID && show_ad_mobile &&
                                            <aside style={{"textSize":"1rem"}}>
                                                <div className={styles.sectionFullwidth} key={`ad_`+nth_ad}>
                                                    <AdSense id={ad_ids[nth_ad]} style="inline1" layout="-fc+5g+70-cl-1m"/>
                                                </div>
                                            </aside>
                                    }
                                    <DynamicZoneComponent component={component} index={indexText} anchor_id={anchor} siteSettings={siteSettings}></DynamicZoneComponent>
                                </span>
                            )
                        }
                        )
                    }
            </ArticleWrapper>
            
    </article>
            {
                // recommended pages
                article.recommended_pages && article.recommended_pages.length > 0 && (
                <RelatedPages nodes={article.recommended_pages}></RelatedPages>
            )}
            {process.env.EZOIC_ACCOUNT_ID && 
              <>
                <aside className={styles.centeredAd} dangerouslySetInnerHTML={{ __html: `
                <div id="ezoic-pub-ad-placeholder-106"> </div>
                `}}/>
              </>
            }
        
            {process.env.ADSENSE_CLIENT_ID &&
                <aside className={styles.centeredAd}>
                    <AdSense id="1219298507" style="fullwidth1" layout="-fc+5g+70-cl-1m"/>
                </aside>
            }
    </>
  )
}

export default BlogPost
