import React from "react"

import {getPageUrl} from '../lib/helpers'


// see https://github.com/baobabKoodaa/gatsby-starter-infinite-scroll/

const InfiniteScrollGrid = (props) => {
    const g = props.globalState

    var articles = g.articles
    const currentPageNum = g.cursor
    const countPages = 1 // props.pageContext.countPages

    const items = []


    var i = 0,
        j = 0,
        numberOfItemsOnLatestPage = 0

    
    if (g.useInfiniteScroll && g["page" + currentPageNum]) {
        for (var pageNum = currentPageNum-1; ; pageNum++) {
            const key = "page" + pageNum
            //console.log("for key", key)
            if (g[key]) {

                if( !g.stopUpdating ){
                    /* Add gridItems that we have received metadata for. */
                    numberOfItemsOnLatestPage = g[key].length
                    //const numberOfItemsOnLatestPage_articles = getArticleFromSlug( g[key] )
                    var foundNewArticles = false
                    for (j = 0; j < numberOfItemsOnLatestPage; j++) {
                        
                        var slug = g[key][j]

                        if ( !g.journey.includes(slug.canonical_url)){
                            // save this to the journey
                            g.journey.push( slug.canonical_url )
                            // console.log("---> display item",  slug)
                            foundNewArticles = true
                        
                            fetch('/page-data'+getPageUrl(slug)+'page-data.json'
                            ,{
                            headers : {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            }
                            }
                            )
                            .then(function(response){
                                return response.json();
                            },
                            err => {
                                // handle error
                            })
                            .then(function(myJson) {
                                if ( myJson?.result?.data?.strapiPage ){
                                    
                                    const new_articles = g.articles
                                    
                                    
                                    new_articles.push(myJson?.result?.data?.strapiPage)
                                    //g.updateState({ articles:new_articles })
                                    //console.log("how many times? ",j)
                                }
                            },
                            err => {
                                // handle error
                            });
                        
                        }
                    }

                    // Nothing new found so mark as finished
                    if ( !foundNewArticles ){
                        g.updateState({stopUpdating: true})
                    }
                }
            } else {
                /* Don't add anything for pages that the user hasn't fetched (scrolled to) yet. */
                break
            }
        }
    } else {
        /* This 'else' covers special cases when we don't have items in global state.
         * - If a user has JS disabled (we won't be able to manipulate global state).
         * - And the very first render on initial pageload.
         * In these cases we simply render the items of this page (corresponds to a path like "/", "/2", "/3",...)
        props.pageContext.pageImages.forEach((item) =>
            items.push(<div item={item} key={"gi" + i++}>New Grid Item {item}</div>)
        )
         */

        // display the page
        // items.push(
        //     <BlogPost globalState={g} key="post_2" article={article} search_index={process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME} pageUrl={pageUrl} siteSettings={siteSettings} categoryPages={categoryPages}/>
        // )

    }


    return (
        <>
        </>
    )
}

export default InfiniteScrollGrid