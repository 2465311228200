// extracted by mini-css-extract-plugin
export var adSpace250x = "article-wrapper-module--adSpace250x--7f79e";
export var adSpace300x = "article-wrapper-module--adSpace300x--d4a76";
export var adSpace728x90 = "article-wrapper-module--adSpace728x90--4f807";
export var adSpace970x250 = "article-wrapper-module--adSpace970x250--63d44";
export var heroAd = "article-wrapper-module--heroAd--bf30a";
export var heroMiniAd = "article-wrapper-module--heroMiniAd--eafd1";
export var listItem = "article-wrapper-module--listItem--61ebf";
export var main = "article-wrapper-module--main--85edd";
export var nonstickyMenu = "article-wrapper-module--nonstickyMenu--f024c";
export var readMoreImage = "article-wrapper-module--readMoreImage--018b2";
export var readMoreTitle = "article-wrapper-module--readMoreTitle--95e51";
export var relatedTopics = "article-wrapper-module--relatedTopics--0e0f5";
export var rightPanel = "article-wrapper-module--rightPanel--ed36a";
export var rightPanelPadded = "article-wrapper-module--rightPanelPadded--fdb8b";
export var rightSubmenu = "article-wrapper-module--rightSubmenu--e5625";
export var root = "article-wrapper-module--root--e3931";
export var similarPages = "article-wrapper-module--similarPages--d9910";
export var stickableArea = "article-wrapper-module--stickableArea--c8429";
export var stickyMenu = "article-wrapper-module--stickyMenu--9c242";
export var subMenu = "article-wrapper-module--subMenu--69fc2";