import React from "react";

import { graphql } from "gatsby";

import {getPageUrl} from '../lib/helpers'

import Layout from '../containers/layout'

import MetaSchemaSEO from '../components/seo';

import { GlobalStateContext } from "../components/globalState.js"
import View from "../components/view.js"




export const query = graphql`
  query ArticleQuery($pageId: String!) {
    allStrapiPage(
      filter: {categorytopic_pages: {elemMatch: {id: {eq: $pageId}}}}
      sort: {fields: [is_featured,page_value,updatedAt]}
    ) {
      nodes {
        title
        title_short
        slug
        children_pages {
          is_featured
          page_value
          updatedAt
          slug
          title
          title_short
          main_image {
            url
            caption
            alternativeText
            localFile{
              childImageSharp{
                gatsbyImageData(
                  quality: 70,
                  width: 200,
                  aspectRatio: 1,
                  transformOptions: {cropFocus:ENTROPY})
              }
            }
          }
          page_type {
            name
            slug_prefix
          }
        }
      }
    }
    strapiPage(id: {eq: $pageId}) {
      id
      slug
      title
      title_short
      excerpt
      publishedAt
      createdAt
      updatedAt
      page_type {
        name
        slug_prefix
        show_summary_map
        show_table_of_contents
      }
      about_entity { 
        name
        slug
        latitude
        longitude
        contact_information {
          ... on STRAPI__COMPONENT_CONTACT_DETAILS_WEBSITE_URL {
            url
          }
          ... on STRAPI__COMPONENT_CONTACT_DETAILS_ADDRESS {
            address
          }
        }
      }
      next_pages {
        id
        slug
        title
        page_type {
          name
          slug_prefix
        }
        main_image {
          url
          caption
          alternativeText
          localFile{
            childImageSharp{
              gatsbyImageData(
                quality: 70,
                width: 100,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY})
            }
          }
        }
      }
      authors {
        id
        name
        slug
        same_as
        entity_type {
          slug_prefix
          schema_thing_type
          name
        }
        main_image {
          url
          caption
          alternativeText
          localFile{
            childImageSharp{
              gatsbyImageData(
                quality: 80,
                width: 50,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY})
            }
          }
        }
      }
      recommended_pages {
        id
        title
        slug
        primary_topic
        page_type {
          name
          slug_prefix
        }
        main_image {
          url
          caption
          alternativeText
          localFile{
            childImageSharp{
              gatsbyImageData(
                width: 200,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY})
            }
          }
        }
      }
      related_pages {
        id
        title
        slug
        primary_topic
        page_type {
          name
          slug_prefix
        }
        main_image {
          url
          caption
          alternativeText
          localFile{
            childImageSharp{
              gatsbyImageData(
                width: 200,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY})
            }
          }
        }
      }
      parent_page {
        title
        title_short
        slug
        include_parent_when_in_breadcrumb_as_parent
        parent_page {
          title_short
          slug
        }
      }
      main_image {
        url
        caption
        alternativeText
        localFile{
          childImageSharp{
            gatsbyImageData(
              quality: 70,
              width: 350,
              aspectRatio: 1,
              transformOptions: {cropFocus:ENTROPY})
          }
        }
      }
      childPages: children_pages {
        id
        slug
        primary_topic
        title
        title_short
        excerpt
        page_value
        updatedAt
        parent_page {
          title
          title_short
          slug
        }
        primary_entity {
          slug
          name
        }
        page_type {
          name
          slug_prefix
        }
        main_image {
          url
          caption
          alternativeText
          localFile{
            childImageSharp{
              gatsbyImageData(
                quality: 70,
                width: 350,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY})
            }
          }
        }
      }
      body {
        __typename
        ... on STRAPI__COMPONENT_RELATED_CALLOUT_PAGES {
          pages {
            slug
            primary_topic
            page_type {
              name
              slug_prefix
            }
            site_section: page_type {
              name
              slug_prefix
            }
            title
            title_short
            excerpt
            page_value
            updatedAt
            parent_page {
              title
              title_short
              slug
              page_type {
                name
                slug_prefix
              }
            }
            main_image {
              url
              caption
              alternativeText
              localFile{
                childImageSharp{
                  gatsbyImageData(
                    quality: 70,
                    width: 350,
                    aspectRatio: 1,
                    transformOptions: {cropFocus:ENTROPY})
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_MEDIA_IMAGE_GALLERY {
          images {
            url
            caption
            alternativeText
            localFile{
              childImageSharp{
                gatsbyImageData(
                  quality: 80,
                  width: 755
                )
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_MEDIA_IMAGE {
          display_type
          source_html
          single_image {
            url
            caption
            alternativeText
            localFile{
              childImageSharp{
                gatsbyImageData(
                  quality: 80,
                  width: 755
                )
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_SEGMENTS_RICH_TEXT {
          id
          strapi_component
          style
          title
          text {
            data {
              text
              childMarkdownRemark {
                html
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_SEGMENTS_QUESTION_AND_ANSWER {
          id
          question
          answer {
            data {
              answer
              childMarkdownRemark {
                html
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_SEGMENTS_LIST_TOP_N {
          id
          strapi_component
          header_level
          is_ordered
          title
          description {
            data {
              description
            }
          }
          introduction {
            data {
              introduction
            }
          }
          section_image {
            url
            caption
            alternativeText
            localFile{
              childImageSharp{
                gatsbyImageData(
                  width: 605,
                  transformOptions: {cropFocus:ENTROPY})
              }
            }
          }
          about_entity { 
            name
            slug
            latitude
            longitude
            create_index_for_this_page
            main_image {
              url
              caption
              alternativeText
              localFile{
                childImageSharp{
                  gatsbyImageData(
                    width: 200,
                    aspectRatio: 1,
                    transformOptions: {cropFocus:ENTROPY})
                }
              }
            }
            large_main_image {
              url
              caption
              alternativeText
              localFile{
                childImageSharp{
                  gatsbyImageData(
                    width: 200,
                    aspectRatio: 1,
                    transformOptions: {cropFocus:ENTROPY})
                }
              }
            }
          }
          offers {
            id
            title
            offer_html
            external_url
            img_url
            img_alt
            amazon_product_asin
          }
          page {
            slug
            page_type {
              name
              slug_prefix
            }
            title
            parent_page {
              slug
              page_type {
                name
                slug_prefix
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_MEDIA_EXTERNAL_MEDIA {
          id
          external_url
          title
          type
        }
        ... on STRAPI__COMPONENT_MEDIA_HTML {
          id
          raw_html
          outer_container
        }
      }
      primary_entity {
        slug
        name
      }
      categorytopic_pages {
        id
        title
        title_short
        primary_topic
        slug
        page_type {
          name
          slug_prefix
        }
        parent_page {
          title_short
        }
      }
      executive_summary {
        data {
          executive_summary
        }
      }
      body_introduction {
        data {
          body_introduction
          childMarkdownRemark {
            html
          }
        }
      }
      subtitle
      show_comments
    }
    site {
      siteMetadata {
      siteUrl
      }
    }
    strapiSiteSetting {
      amazon_affiliate_trackingid
    }
  }
`;




const Page = ( {location, data} ) => {
  // using old query
  // const article = data.strapi.page;
  const categoryPages = data.allStrapiPage;
  const article = data.strapiPage;
  const siteSettings = data.strapiSiteSetting;

  const isSSR = typeof window === "undefined"
  
  function afterPageLoad(){
    // if ezoic
    if( process.env.EZOIC_ACCOUNT_ID ){
      var ezstandalone = ezstandalone || {};
      ezstandalone.cmd = ezstandalone.cmd || [];
      ezstandalone.cmd.push(function() {
        // If the placeholder ID’s change make sure to redefine, if not just call refresh
        ezstandalone.define(100,102,101,104);
        if (ezstandalone.enabled) {
          ezstandalone.refresh();
        } else {
          ezstandalone.enable();
          ezstandalone.display();
        }
      });
    }

    // if adsense
    if ( process.env.ADSENSE_CLIENT_ID ){
        var window = window || {};
        window.adsbygoogle = window.adsbygoogle || [];
        const nGoogleAds = document.getElementsByClassName("adsbygoogle").length;
        // load ad for each
        for (let i = 0; i < nGoogleAds; i++) {
          if( 'childPages' in document.getElementsByClassName("adsbygoogle")[i] && document.getElementsByClassName("adsbygoogle")[i].childPages.length == 0 ){
            (adsbygoogle = window.adsbygoogle || []).push({});
          }
        }
    }
  };

  typeof window !== `undefined` && window.addEventListener('popstate', afterPageLoad());

  const pageUrl = data.site.siteMetadata.siteUrl+getPageUrl(article)
  return (
    <Layout>
      <GlobalStateContext.Consumer>
          {globalState => (
              <>
              <MetaSchemaSEO
                title = {article.title}
                article = {article}
              />
              <View globalState={globalState} key="blogpost" article={article} pageUrl={pageUrl} siteSettings={siteSettings} categoryPages={categoryPages}/>
              </>
          )}
      </GlobalStateContext.Consumer>
      
    </Layout>
  );

};

export default Page;