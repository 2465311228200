import React from 'react'

import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { getEntityUrl} from '../lib/helpers'
import {format} from 'date-fns';

import Breadcrumb from './breadcrumb';
import Container from './container'

import * as styles from './article-header-infinite.module.css'


export default function ArticleHeader(props) {
    const article = props.article
    
    // author spacing
    var author_spacer = ""
    var author_spacer_last = ""
    if(article.authors.length == 2){
        author_spacer_last = " and "
    } else if (article.authors.length > 2) {
        author_spacer = ", "
        author_spacer_last = ", and "
    }


    return (
        <>
            <div className={styles.pageHeader}>
                <Container>
                    <Breadcrumb thisPage={article}></Breadcrumb>
                    <h1 className={styles.title}>{article.title}</h1>
                </Container>
            </div>
            
            <div className={styles.pageSubHeader}>
                {article.authors && article.authors.length>0 &&
                    
                    <div className={styles.authors}>
                        <div className={styles.avatars}>
                        {article.authors.map((entity,index) => entity.main_image && (
                            <span key={index} className={styles.avatar}>
                                <GatsbyImage image={getImage(entity?.main_image?.localFile)} alt={entity.main_image.alternativeText ? entity.main_image.alternativeText : entity.name } />
                            </span>
                        ))}
                        </div>
                        <div className={styles.mediaBody}>
                            By {article.authors.map((entity, index) => (
                                <span key={"author_"+index}>
                                    <Link key={entity._id} to={getEntityUrl(entity)}>{entity.name}</Link>
                                    { index<(article.authors.length-2) ? author_spacer:""}
                                    { index==(article.authors.length-2) ? author_spacer_last:""}
                                </span>
                            ))}
                            <small className={styles.dateBlock}>
                                { article.updatedAt && article.createdAt && (Date.parse(article.updatedAt)-60*60*24 > Date.parse(article.createdAt)) && 
                                    <>{format( Date.parse(article.updatedAt), "MMMM do, yyyy")}</>
                                }
                            </small>
                        </div>
                    </div>
                }
                { (!article.authors || (article.authors && article.authors.length==0)) &&
                    <small className={styles.dateBlock}>
                        { article.updatedAt && 
                            <>{format( Date.parse(article.updatedAt), "MMMM do, yyyy")}</>
                        }
                    </small>
                }
                {article.authorities && article.authorities>0 && 
                    <ul>
                        {article.authorities.map((entity,index) => (
                            <li key={index}>
                                <Link to={getEntityUrl(entity)}>{entity.name}</Link>
                            </li>
                        ))}
                    </ul>
                }
                { article.summary && 
                <Container>
                        <blockquote><i>{article.summary}</i></blockquote>
                </Container>
                }
            </div>
        
        </>
    )
  }