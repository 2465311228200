import React from "react";
import Markdown from "react-markdown";

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { getPageUrl } from '../lib/helpers';

import AdSense from "./adsense";
import ArticleFooter from "../components/article-footer-infinite"
import ArticleHeader from "../components/article-header-infinite"
import ArticleWrapper from "../components/article-wrapper"
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import DynamicZoneComponent from '../components/dynamic-zone-component'
import OfferAffiliateNotice from "../components/offer-affiliatenotice"
import RelatedPages from '../components/related-pages'
import SearchBar from '../components/search-bar'

import * as styles from './blog-post-infinite.module.css'


function string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaaeeeeiiiioooouuuunc------";

    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

function BlogPostInfinite ({article, search_index, page_number}) {
  const image = getImage(article?.main_image?.localFile)

  var is_affiliate = []
  // loop through data and determine if there are affiliate offers
  // check list for offers
  if(article?.body){
      for(let i = 0; i < article.body.length; i++){
          for(let j = 0; j < article.body[i]?.offers?.length; j++){
            is_affiliate.push(true)
          }
      }
  }
  
  // devine section numbers if more than one
  var sectionNumber = 0
  var nth_ad = 0
  var show_about_sidecallout = false
  // determine ads for infinite
  var ezoic_ad_ids = ["128","129"]
  var ad_ids = []
  
  if (process.env.EZOIC_ACCOUNT_ID) {
      ad_ids = [
            118, // mid_content
            119, // long_content
            120, // longer_content
            121, // longest_content
            122, // incontent_5
            110, // incontent_6
            123, // incontent_7
            124, // incontent_8
            125, // incontent_9
            126, // incontent_10
            127  // incontent_11
        ]
      if(ezstandalone){
        ezstandalone.displayMore(
            118, // mid_content
            119, // long_content
            120, // longer_content
            121, // longest_content
            122, // incontent_5
            110, // incontent_6
            123, // incontent_7
            124, // incontent_8
            125, // incontent_9
            126, // incontent_10
            127  // incontent_11
        )
      }
    }
  if (process.env.ADSENSE_CLIENT_ID) {
      ad_ids = [8499360713,8499360713,8499360713,8499360713]
  }

  if ( typeof window !== `undefined` && window.adsbygoogle ){
    const nGoogleAds = document.getElementsByClassName("adsbygoogle").length;
    for (let i = 0; i < nGoogleAds; i++) {
      if( 'childPages' in document.getElementsByClassName("adsbygoogle")[i] && document.getElementsByClassName("adsbygoogle")[i].children.length == 0 ){
        (adsbygoogle = window.adsbygoogle || []).push({});
      }
    }
  }

  // loop back to the beginning of list
  nth_ad = nth_ad % ad_ids.length
    
  return (
    <>
        <ArticleHeader article={article}></ArticleHeader>
        <ArticleWrapper article={article} ezoic_ad_ids={ezoic_ad_ids} show_about_sidecallout={show_about_sidecallout}>
            {
            // main image if no children and show_main_image on parent is not false
            article.main_image && article.children && article.children.length === 0 && article.page_type?.show_hero_image != false &&
            <div className={styles.hero}>
                <GatsbyImage image={image} alt={article.main_image.alternativeText ? article.main_image.alternativeText : article.title } />
                {article.main_image.caption}
            </div>
            }
                { article.body_introduction && article.body_introduction.data && 
                
                    <section id="introdction" dangerouslySetInnerHTML={{__html:article.body_introduction.data.childMarkdownRemark.html}} />
                
                }
                {
                    // display body components table of contents
                    article.body && article.body.length > 2 &&
                        <nav id="toc">
                            <ul className={styles.toc_list}>
                            { article.body.map((component,index) => component && (component?.title || component?.question) && (
                                // if there is a title, then add it to table of contents
                                <li key={"shortcut_"+index}>
                                    { component.title &&
                                    <a href={getPageUrl(article)+"#"+string_to_slug(component.title)}>{component.title}</a>
                                    }
                                    { component.question &&
                                    <a href={getPageUrl(article)+"#"+string_to_slug(component.question)}>{component.question}</a>
                                    }
                                </li>
                            ))
                            }
                            </ul>
                        </nav>
                }

                {
                // add children posts if any
                article.children && article.children.length > 0 && (
                    <BlogPostPreviewList
                        nodes={article.children}
                        max_n="5"
                        page_type={article.page_type}
                    />
                )}

                {
                    // show affiliate notice
                    is_affiliate && is_affiliate.length > 0 &&
                    <aside>
                        <OfferAffiliateNotice></OfferAffiliateNotice>
                    </aside>
                }


                
                {
                    // display body components
                    article.body && article.body.map((component, index) => {
                        // increment
                        var anchor = null
                        var indexText = ""
                        var show_ad = false
                        var show_ad_mobile = false
                        if ((index+1) % 2 === 0){
                            nth_ad = nth_ad + 1
                            show_ad = true
                        } else if ((index) % 1 === 0 && component.__typename != 'STRAPI__COMPONENT_RELATED_CALLOUT_PAGES'){
                            // else show mobile ad
                            nth_ad = nth_ad + 1
                            show_ad_mobile = true
                        }
                        if (component.title){
                            anchor = string_to_slug( component.title )
                            sectionNumber = sectionNumber + 1
                            indexText = sectionNumber+". "
                        }
                        if (component.Question){
                            anchor = string_to_slug( component.Question )
                            sectionNumber = sectionNumber + 1
                            indexText = sectionNumber+". "
                        }
                        return (component && 
                            <span key={"comp_"+index}>
                                { process.env.EZOIC_ACCOUNT_ID && show_ad
                                    ? <>
                                        <div className={styles.sectionFullwidth} key={`ad_`+nth_ad}>
                                            <div className={styles.centeredAd} dangerouslySetInnerHTML={{ __html: `
                                                <div id="ezoic-pub-ad-placeholder-`+ad_ids[nth_ad]+`"> </div>`}}/>
                                        </div>
                                    </>
                                    : null
                                }
                                {process.env.ADSENSE_CLIENT_ID && show_ad &&
                                    <div className={styles.sectionFullwidth} key={`ad_`+nth_ad}>
                                        <AdSense id={ad_ids[nth_ad]} style="inline1" layout="-fc+5g+70-cl-1m"/>
                                    </div>
                                }
                                {process.env.ADSENSE_CLIENT_ID && show_ad_mobile &&
                                        <aside style={{"textSize":"1rem"}}>
                                            <div className={styles.sectionFullwidth} key={`ad_`+nth_ad}>
                                                <AdSense id={ad_ids[nth_ad]} style="inline1" layout="-fc+5g+70-cl-1m"/>
                                            </div>
                                        </aside>
                                }
                                <DynamicZoneComponent component={component} index={indexText} anchor_id={anchor}></DynamicZoneComponent>
                            </span>
                        )
                    }
                    )
                }
                <div><Container>
                    <Markdown children={article.body_conclusion} skipHtml={true} />
                </Container></div>
            </ArticleWrapper>
            <Container>
                <ArticleFooter ></ArticleFooter>
                {search_index &&
                    <SearchBar></SearchBar>
                }
            </Container>

        {
            // related pages
            article.recommended_pages && article.recommended_pages.length > 0 && (
            <RelatedPages nodes={article.recommended_pages}></RelatedPages>
        )}
        { 
            // if no related pages then show an ad
            (!article.recommended_pages || (article.recommended_pages && article.recommended_pages.length == 0)) &&
            <>
                { process.env.EZOIC_ACCOUNT_ID && 
                    <div className={styles.centeredAd} dangerouslySetInnerHTML={{ __html: `
                        <div id="ezoic-pub-ad-placeholder-130"> </div>
                    `}}/>
                }
                
                {process.env.ADSENSE_CLIENT_ID &&
                    <aside className={styles.centeredAd}>
                        <AdSense id="1219298507" style="fullwidth1" layout="-fc+5g+70-cl-1m"/>
                    </aside>
                }
            </>
        }
    </>
  )
}

export default BlogPostInfinite
