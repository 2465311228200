// extracted by mini-css-extract-plugin
export var authors = "article-header-infinite-module--authors--f9d50";
export var avatar = "article-header-infinite-module--avatar--4a41c";
export var avatars = "article-header-infinite-module--avatars--794df";
export var breadcrumb = "article-header-infinite-module--breadcrumb--d32a4";
export var breadcrumb_link = "article-header-infinite-module--breadcrumb_link--6afba";
export var dateBlock = "article-header-infinite-module--dateBlock--f36d8";
export var hero = "article-header-infinite-module--hero--c5ca5";
export var mediaBody = "article-header-infinite-module--mediaBody--a7f07";
export var pageHeader = "article-header-infinite-module--pageHeader--d5935";
export var pageSubHeader = "article-header-infinite-module--pageSubHeader--768e2";
export var svg1 = "article-header-infinite-module--svg1--bb726";
export var svg2 = "article-header-infinite-module--svg2--2a1d9";
export var title = "article-header-infinite-module--title--c8d24";